<template>
  <div class="footer">
    <div class="wrapper">
      <a href="https://www.nantian.com.cn/" target="_blank" class="all">
        <div class="com-logo">
          <img src="../../assets/about/Image 1@2x.png" alt="" />
          <a target="_blank">了解更多产品</a>
        </div>
        <ul class="Fintech">
          <li>金融科技</li>
          <li><img src="../../assets/about/down.png" alt="" /></li>
          <li><a target="_blank">生态易聚变平台(小程序框架)</a></li>
          <li><a target="_blank">银行服务网格核心系统</a></li>
          <li><a target="_blank">互联网核心系统</a></li>
          <li><a target="_blank">渠道数智化解决方案</a></li>
        </ul>
        <ul class="digital-gov">
          <li>数字政府</li>
          <li><img src="../../assets/about/down.png" alt="" /></li>
          <li><a target="_blank">智慧景区解决方案</a></li>
          <li><a target="_blank">服刑人员资金管理系统</a></li>
          <li><a target="_blank">互联网+政务一体化平台</a></li>
          <li><a target="_blank">城市基层治理综合解决方案</a></li>
        </ul>
        <ul class="industry-dig">
          <li>行业数字化</li>
          <li><img src="../../assets/about/down.png" alt="" /></li>
          <li><a target="_blank">自动售检票（AFC）系统</a></li>
          <li><a target="_blank">地铁票务管理系统</a></li>
          <li><a target="_blank">AFC运维管理系统</a></li>
          <li><a target="_blank">慧眼平台</a></li>
        </ul>
        <ul class="normal">
          <li>通用</li>
          <li><img src="../../assets/about/down.png" alt="" /></li>
          <li><a target="_blank">统一服务平台</a></li>
          <li><a target="_blank">移动应用平台</a></li>
          <li><a target="_blank">智能营销平台</a></li>
          <li><a target="_blank">数据可视化平台</a></li>
        </ul>
      </a>
    </div>
    <div class="split-line"></div>
    <div class="copyright">
      Copyright&nbsp;©&nbsp;{{
        new Date().getFullYear()
      }}&nbsp;云南南天电子信息产业股份有限公司&nbsp;滇ICP备&nbsp;05009028号-1
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="less" scoped>
li {
  display: block;
}
.footer {
  width: 100%;
  min-width: 980px;
  height: 300px;
  background-color: #333333;
  margin-top: 55px;
  padding: 15px 0 22px 0;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  box-sizing: border-box;
  .wrapper {
    width: 82%;
    min-width: 980px;
    margin: 0 auto;
    ul {
      text-align: center;
      li > img {
        width: 16px;
        height: 8px;
      }
      li:nth-child(n + 3) {
        margin-top: 13px;
      }
    }
    .all {
      text-decoration: none;
      color: #999999;
      display: flex;
      justify-content: space-between;
    }
    .com-logo {
      text-align: center;
      img {
        width: 110px;
        height: 34px;
        display: block;
        margin: 51px 0 17px;
      }
    }
  }
  .split-line {
    width: 100%;
    height: 1px;
    background-color: rgba(102, 102, 102, 1);
    margin-top: 30px;
  }
  .copyright {
    display: flex;
    align-items: center;
    width: 430px;
    height: 34px;
    margin: 22px auto 0;
    text-align: center;
  }
}
</style>
