<template>
    <div class="feedback">
        <!-- 反馈弹框 -->
        <!-- :close-on-click-modal="false"  就可以关闭默认的空白关闭弹框  -->
        <el-dialog title="反馈详情"
                :visible.sync="dialogFormVisible"
                width="480px"
                :close-on-click-modal="false"
                @close="resetForm('ruleForm')"
                class="feedbackDialog"
                :modal="true"
                >
        <el-form :label-position="labelPosition"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="80px">
            <el-form-item label="详情:"
                        prop="info"
                        class="labelStyle_dialog">
            <el-input v-model="ruleForm.info"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入反馈详细内容"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="邮箱:"
                        prop="email">
            <el-input placeholder="请输入邮箱"
                        v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="图片:"
                        class="changImg_dialog">
            <input type="file"
                    id="file"
                    @change="chooseFile()"
                    style="display: none" />
            <div class="showBigBox_dialog">
                <div class="showImgBox_dialog">
                <div v-if="isShow"
                    class="isShowBox_dialog">
                    <div class="imgbox_dialog"
                        v-for="(item, index) in fileImg"
                        :key="index">
                    <!-- <img :src="item" alt="" class="showImg" /> -->
                    <el-image :src="item"
                                :preview-src-list="fileImg"
                                class="showImg_dialog">
                    </el-image>
                    <img src="../../assets/close.png"
                        alt=""
                        class="closeimg_dialog"
                        @click="delImg(index)" />
                    </div>
                </div>
                <div class="uploadBox_dialog"
                    @click="uploadFile()"
                    v-if="fileImg.length < 3">
                    <img src="../../assets//plus.png"
                        alt=""
                        class="plusImg_dialog" />
                </div>
                </div>
            </div>
            </el-form-item>
            <el-form-item class="btnStyle_dialog">
            <el-button type="primary"
                        class="submitStyle_dialog"
                        @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            </el-form-item>
        </el-form>
        </el-dialog>
    </div>
</template>

<script>
const path = require('path')
export default {
    name: 'FeedBack',
    data () {
        return {
            dialogFormVisible: false, //弹框是否显示
            formLabelWidth: "120px",
            labelPosition: "top",
            ruleForm: {
                info: "",
                email: "",
            },
            rules: {
                info: [
                {
                    required: true,
                    message: "请输入反馈详细内容",
                    trigger: "blur",
                },
                ],
                email: [
                {
                    pattern:
                    /^([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/,
                    message: "输入格式不符合",
                    trigger: "blur",
                },
                ],
            },
            isShow: false, //展示图片
            //上传图片的集合
            fileImg: [],
        }
    },
    methods: {
        // 设置反馈弹框显示与隐藏
        openFeedBack () {
            this.dialogFormVisible = !this.dialogFormVisible;
        },
        // 提交表单
        submitForm (formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                let data = {};
                data.feedback_detail = this.ruleForm.info;
                data.email = this.ruleForm.email;
                data.img_url_list = this.fileImg;
                this.$api.addfeedBack(data).then((res) => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: "反馈成功",
                            type: "success",
                        });
                        this.dialogFormVisible = false;
                        this.ruleForm.info = "";
                        this.ruleForm.email = "";
                        this.fileImg = [];
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error"
                        })
                    }
                });
            } else {
                return false;
            }
        });
        },
        // 重置表单
        resetForm (formName) {
            this.$refs[formName].resetFields();
            this.dialogFormVisible = false;
            this.ruleForm.email = "";
            this.fileImg = [];
        },
        // 上传文件
        uploadFile () {
            let input = document.getElementById("file");
            input.value = "";

            input.click();
        },
        //删除图片
        delImg (index) {
            this.fileImg.splice(index, 1);
        },
        //选择文件
        chooseFile () {
            let that = this;
            let input = document.getElementById("file");
            let file = input.files[0];
            let name = path.extname(file.name);
            input.value = "";
            if (name != ".jpg" && name != ".jpeg" && name != ".png") {
                this.$message.error("请上传图片");
                return;
        }
        var reads = new FileReader();
        reads.readAsDataURL(file);
        reads.onload = function () {
            // that.imageUrl = this.result;
            let base64 = this.result;
            let img = new Image();
            img.src = base64;
            img.onload = function () {
                that.imgInfo = {
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                };

                that.$api.upLoadImg({ file: file }).then((res) => {
                    that.fileImg.push(res.data.fileUrl);
                    that.isShow = !this.isShow;
                });
            };
        };
        },
    },
    mounted(){
        this.$bus.$on('openFeedBack', this.openFeedBack)
    },
    destroyed(){
        // 组件销毁时关闭事件总线
        this.$bus.$off('openFeedBack', this.openFeedBack)
    }
}
</script>

<style lang="less" scoped>
/* 反馈弹框的样式 */
.feedbackDialog {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;//没有遮罩层时双重滚动条bug
}
.showBigBox_dialog {
  /* position: relative; */
 }
.imgbox_dialog {
  position: relative;
}
.closeimg_dialog {
  position: absolute;
  top: -10px;
  left: 85px;
  width: 28px;
  height: 28px;
}
.showImgBox_dialog {
  display: flex;
}
.isShowBox_dialog {
  display: flex;
  /* justify-content: space-between; */
}
/* .imgbox {
  width: 100px;
  height: 100px;
} */
.showImg_dialog {
  width: 100px;
  height: 100px;
  margin-right: 16px;
  border-radius: 10px;
}

.uploadBox_dialog {
  width: 100px;
  height: 100px;
  /* padding: 20px; */
  border-radius: 10px;
  border: 1px dashed #dcdfe6;
  text-align: center;
}
.plusImg_dialog {
  margin-top: 30px;
  /* line-height: 100px; */
}

.btnStyle_dialog {
  display: flex;
  justify-content: center;
}
.submitStyle_dialog {
  background: #3e7aec;
}
.popbtnStyle_dialog {
  width: 70px;
  height: 30px;
  justify-content: center;
  padding: 0 0;
} 
</style>