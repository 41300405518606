<template>
  <div class="header">
    <div class="container">
      <div class="wrapper">
        <img
          class="logo"
          src="../../assets/ssh_Aechoterm/logo.png"
          alt="ssh工具-Aechoterm"
        />
        <div class="right">
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/about">功能演示</router-link>
          <!-- <router-link active-class="active" to="/community">知识社区</router-link> -->
          <div class="feedback" @click="openFB">
            <!-- <span @click="tabClick('/home')">反馈</span> -->
            <span>反馈</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      path: "/home",
    };
  },
  methods: {
    tabClick(path) {
      this.$router.push(path);
      this.path = path;
    },
    openFB() {
      // this.openFeedBack();
      //向feedback组件发送事件
      this.$bus.$emit("openFeedBack");
    },
  },
  mounted() {
    // 初始化data中的path路径，防止用户刷新时选中状态对不上
    this.path = this.$route.path;
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  min-width: 980px;
  height: 60px;
  .container {
    position: fixed;
    z-index: 999;
    width: 100%;
    min-width: 980px;
    height: 60px;
    background: #3e7aec;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
      width: 1200px;
      height: 44px;
      display: flex;
      justify-content: space-between;
      .logo {
        width: 191px;
        height: 44px;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        .active {
          background-color: #4f88f5;
          border-radius: 8px;
        }
        a,
        .feedback {
          text-decoration: none;
          color: #ffffff;
          font-size: 16px;
          display: block;
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          margin-left: 44px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
