<template>
  <div class="download">
    <el-popover :placement="placement" v-model="thisVisible">
      <p style="text-align: center">请选择{{ title }}的安装包类型</p>
      <div :style="['btnStyle']">
        <!-- type="info" -->
        <el-button
          size="mini"
          type="primary"
          :class="[btnType == '1' ? 'popbtnLeftStyle' : 'rowLeftStyle']"
          @click="leftDown"
          >{{ leftText }}</el-button
        >
        <button
          v-show="rightText"
          :class="[btnType == '1' ? 'popbtnStyle' : 'rowStyle']"
          @click="rightDown"
        >
          {{ rightText }}
        </button>
      </div>
      <div slot="reference">
        <slot></slot>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "download",
  props: {
    visible: {
      //控制弹框的显示与隐藏
      type: Boolean,
    },
    placement: {
      //弹出框出现位置
      type: String,
      default: "bottom",
    },
    title: {
      //标题
      type: String,
    },
    leftText: {
      //左边安装包文字
      type: String,
    },
    rightText: {
      //右边安装包文字
      type: String,
    },
    // 按钮显示方式
    btnType: {
      type: String,
    },
  },
  model: {
    prop: "visible",
    event: "changeVisible",
  },
  data() {
    return {
      thisVisible: false,
    };
  },
  watch: {
    visible: {
      //监听父组件传进来的值，修改了子组件的thisVisible跟着修改
      handler: function(val) {
        this.thisVisible = val;
      },
      immediate: true,
    },
    thisVisible: {
      //监听子组件的thisVisible，修改了通知父组件修改
      handler: function(val) {
        this.$emit("changeVisible", val);
      },
      immediate: true,
    },
  },
  methods: {
    leftDown() {
      this.$emit("leftDown");
    },
    rightDown() {
      this.$emit("rightDown");
    },
  },
};
</script>

<style lang="less" scoped>
.popbtnLeftStyle {
  width: 70px;
  height: 30px;
  justify-content: center;
  border: none;
  color: #fff;
  background: #409eff;
  margin-left: 10px;
  padding: 0 0;
}
.popbtnStyle {
  cursor: pointer;
  width: 70px;
  height: 30px;
  justify-content: center;
  border: none;
  color: #fff;
  background: #909399;
  margin-left: 10px;
  padding: 0 0;
}
.btnStyle {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.rowLeftStyle {
  display: block;
  // width: 70px;
  width: 100%;
  height: 30px;
  justify-content: center;
  padding: 0 0;
  border: none;
  color: #fff;
  background: #409eff;
  margin-left: 0;
  margin-top: 10px;
}
.rowStyle {
  cursor: pointer;
  display: block;
  // width: 70px;
  width: 100%;
  height: 30px;
  justify-content: center;
  padding: 0 0;
  border: none;
  color: #fff;
  background: #909399;
  margin-left: 0;
  margin-top: 10px;
}
</style>
