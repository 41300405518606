<template>
  <div @click="handleBackTop"
       v-show="isShowBackTop"
       class="backTop">
    <img src="../../assets/about/backTop.png"
         alt="">
  </div>
</template>

<script>
export default {
  name: 'BackTop',
  data () {
    return {
      isShowBackTop: false
    }
  },
  methods: {
    // 回到顶部按钮的点击
    handleBackTop () {
      if (document.body) {
        document.body.scrollIntoView({  })
      } else {
        document.documentElement.scrollIntoView({  })
      }
    },
    //滚动条滚动距离大于1000时显示，否则隐藏
    scrollListener () {
      const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop > 1000) {
        this.isShowBackTop = true
      } else {
        this.isShowBackTop = false
      }
    }
  },
  mounted () {
    //初始页面宽度小于等于1060时不监听滚动，大于1060才监听
    const Width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (Width > 1060) {
      window.addEventListener('scroll', this.scrollListener, true)
    }
    //当浏览器大小改变时，控制backTop组件的显示与隐藏
    window.onresize = () => {
      const Width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (Width <= 1060) {
        this.isShowBackTop = false
        window.removeEventListener('scroll', this.scrollListener, true)
      } else {
        this.scrollListener()
        window.addEventListener('scroll', this.scrollListener, true)
      }
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollListener, true)
  }
}
</script>

<style lang="less" scoped>
.backTop {
  position: fixed;
  right: 1%;
  // bottom: 355px;
  bottom: 43%;
  width: 40px;
  height: 46px;
  cursor: pointer;
  img {
    width: 40px;
    height: 46px;
  }
}
</style>