<template>
  <div class="log">
    <div class="container">
      <div class="header">
        <h4>更新日志</h4>
      </div>
      <div class="mainBox">
        <!-- 内容区域 -->
        <div class="main" ref="main">
          <el-timeline>
            <el-timeline-item
              v-for="item in UpdateLog"
              :key="item.version_info_id"
              :timestamp="item.create_date"
              hide-timestamp
            >
              <div class="version">
                <span v-if="item.file_type == 1">V{{ item.version_code }}</span>
                <span v-if="item.file_type == 2"
                  >V{{ item.parent_version_code }}&nbsp;&nbsp;-&nbsp;&nbsp;{{
                    item.version_code
                  }}</span
                >
              </div>
              <div class="timestamp">{{ item.create_date }}</div>
              <div class="contant">
                <!-- <div v-html="item.version_detail"></div> -->
                <pre>{{ item.version_detail }}</pre>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 侧边栏 -->
        <div class="aside">
          <!-- 下载区域 -->
          <div class="downloadBox" ref="download">
            <el-divider content-position="center">立即下载最新版</el-divider>
            <div class="btnBox">
              <Download
                v-model="winVisible"
                placement="right"
                title="win"
                leftText="32位"
                rightText="64位"
                :btnType="'1'"
                @leftDown="win32dowsDown"
                @rightDown="win64dowsDown"
              >
                <template>
                  <div class="winBtn"></div>
                </template>
              </Download>
              <Download
                v-model="macVisible"
                placement="right"
                title="mac"
                leftText="Mac"
                rightText="Mac_M1"
                :btnType="'1'"
                @leftDown="macdowsDown"
                @rightDown="macM1dowsDown"
              >
                <template>
                  <div class="macBtn"></div>
                </template>
              </Download>
              <Download
                v-model="linuxVisible"
                placement="right"
                title="linux&信创"
                leftText="麒麟/统信等Debian AMD架构"
                rightText="麒麟/统信等Debian ARM架构"
                :btnType="'2'"
                @leftDown="DebinDown"
                @rightDown="DebianARMDown"
              >
                <template>
                  <div class="linuxBtn"></div>
                </template>
              </Download>
              <Download
                v-model="androidVisiable"
                placement="right"
                title="Android"
                leftText="Android系统"
                :btnType="'2'"
                @leftDown="AndroidDown"
              >
                <template>
                  <div class="androidBtn"></div>
                </template>
              </Download>
              <Download
                v-model="iosVisiable"
                placement="right"
                title="IOS"
                leftText="IOS系统"
                :btnType="'2'"
                @leftDown="IOSDOWN"
              >
                <template>
                  <div class="iosBtn"></div>
                </template>
              </Download>
            </div>
          </div>
          <!-- 文章推荐 -->
          <div class="recommend" ref="recommend">
            <!-- <h4>文章推荐</h4>
            <div class="textBox">
              <el-link v-for="(item,index) in postList"
                       :key="index"
                       href=""
                       target="_blank">{{item.text}}</el-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 引入底部 -->
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer/footer.vue"; // 底部
import Download from "../components/Download/Download.vue"; // 下载的弹出框

import downloadMixins from "../mixins/download.js";
import getOSInfoMixins from "../mixins/getOSInfo.js"; // 获取操作系统的信息

export default {
  name: "Log",
  components: {
    Footer,
    Download,
  },
  mixins: [downloadMixins, getOSInfoMixins],
  data() {
    return {
      winVisible: false,
      macVisible: false,
      linuxVisible: false,
      androidVisible: false,
      iosVisiable: false,
      //更新日志信息
      UpdateLog: [],
      //文章推荐
      postList: [
        { text: "1、程序员如何获得高并发的经验？" },
        { text: "2、Python实战：截图识别文字，过万使用量版本!（附源码!!）" },
        { text: "3、.Net Core中使用ElasticSearch" },
        {
          text:
            "4、.net core 和 WPF 开发升讯威在线客服系统：使用本地IP数据库实现访客来源快",
        },
        { text: "5、图片处理看这篇就完了「GitHub 热点速览 v.21.48」" },
        { text: "6、既生瑜何生亮 access_token VS refresh_token" },
        {
          text:
            "7、基于echarts 24种数据可视化展示，填充数据就可用，动手能力强的还可以DIY",
        },
        { text: "8、高并发下秒杀商品，必须知道的9个细节" },
        { text: "9、.NET开源免费图表组件库" },
        { text: "10、理解微前端技术原理" },
      ],
    };
  },
  mounted() {
    this.update(); //检查更新
    this.getUpdateLog(); //获取更新日志
  },
  watch: {
    UpdateLog: "setHeight", //设置推荐文章的高度
  },
  methods: {
    getUpdateLog() {
      let data = { version_type: this.getOSInfo() };
      this.$api.getUpdateLogData(data).then((res) => {
        this.UpdateLog = res.data.data.list;
        if (data.version_type == 3 || data.version_type == 4) {
          this.UpdateLog = this.UpdateLog.filter((item) => {
            return item.file_type === 1;
          });
        }
        for (let i in this.UpdateLog) {
          this.UpdateLog[i].version_detail = this.UpdateLog[
            i
          ].version_detail.replace(/^<br\/>*|<br\/>*$/g, ""); //去除头尾<br/>
          this.UpdateLog[i].version_detail = this.UpdateLog[
            i
          ].version_detail.replace(/(\s+)?<br(\s+)?\/?>(\s+)?/gi, "\n"); //<br/>换成\n
          this.UpdateLog[i].version_detail = this.UpdateLog[
            i
          ].version_detail.replace(/^\s*|\s*$/g, ""); //去除头尾空格
          //增量包版本号对应最近的安装包版本号
          if (this.UpdateLog[i].file_type == 2) {
            for (let j = i; j < this.UpdateLog.length; j++) {
              if (this.UpdateLog[j].file_type == 1) {
                this.UpdateLog[i].parent_version_code = this.UpdateLog[
                  j
                ].version_code;
                break;
              }
            }
          }
        }
      });
    },
    //文章推荐的div高度与左侧内容块平行
    setHeight() {
      this.$nextTick(function() {
        // 仅在整个视图都被渲染之后才会运行的代码
        this.$refs.recommend.style.minHeight =
          this.$refs.main.offsetHeight -
          this.$refs.download.offsetHeight -
          1 +
          "px";
      });
    },
  },
};
</script>

<style lang="less" scoped>
.log {
  width: 100%;
  min-width: 980px;
  background: #f7f7f7;
  h4 {
    margin: 0;
  }
  .container {
    width: 961px;
    margin: 0 auto;
    .header {
      background-color: #fff;
      padding: 14px 32px;
      height: 50px;
      width: 100%;
      line-height: 22px;
      box-sizing: border-box;
    }
    .mainBox {
      margin-top: 1px;
      width: 100%;
      display: flex;
      min-height: 500px;
      .main {
        background-color: #fff;
        width: 700px;
        margin-right: 1px;
        padding: 20px;
        box-sizing: border-box;
        .el-timeline {
          /deep/.el-timeline-item__node {
            width: 29px;
            height: 29px;
            background-image: url("../assets/timeline.png");
            left: -10px;
            top: -4px;
          }
          /deep/.el-timeline-item__tail {
            border-left: 2px dashed #979797;
          }
          .version {
            height: 28px;
            font-size: 20px;
            font-weight: 600;
            color: #333333;
            line-height: 28px;
          }
          .timestamp {
            margin-top: 1px;
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }
          .contant {
            margin: 20px 0;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            pre {
              white-space: pre-line;
            }
          }
        }
      }
      .aside {
        width: 260px;
        height: 100%;
        min-height: 500px;
        .downloadBox {
          background-color: #fff;
          width: 100%;
          height: 100%;
          padding: 20px;
          box-sizing: border-box;
          .el-divider--horizontal {
            margin: 10px 0;
          }
          .el-divider__text {
            width: 120px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
          .btnBox {
            .winBtn {
              background: url("../assets/windowsDl.png") no-repeat;
              margin-top: 40px;
              cursor: pointer;
            }
            .macBtn {
              background: url("../assets/macOSDl.png") no-repeat;
              margin-top: 12px;
              cursor: pointer;
            }
            .linuxBtn {
              background: url("../assets/linuxDl.png") no-repeat;
              margin-top: 12px;
              cursor: pointer;
            }
            .androidBtn {
              background: url("../assets/androidDl.png") no-repeat;
              margin-top: 12px;
              cursor: pointer;
            }
            .iosBtn {
              background: url("../assets/iosDl.png") no-repeat;
              margin-top: 12px;
              cursor: pointer;
            }
            .winBtn,
            .macBtn,
            .linuxBtn,
            .androidBtn,
            .iosBtn {
              width: 220px;
              height: 50px;
              border-radius: 8px;
              background-size: 100%;
            }
            .winBtn:hover,
            .macBtn:hover,
            .linuxBtn,
            .androidBtn,
            .iosBtn:hover {
              opacity: 0.9;
            }
          }
        }
        .recommend {
          margin-top: 1px;
          background-color: #fff;
          width: 100%;
          padding: 16px 20px;
          box-sizing: border-box;
          .textBox {
            .el-link.el-link--default {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
