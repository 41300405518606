export default {
  methods: {
    // 获取操作系统的信息
    getOSInfo() {
      const ua = navigator.userAgent.toLowerCase();
      console.log("ua: ", ua);
      const isMac = ua.indexOf("mac") > -1;
      const isLinux = ua.indexOf("linux") > -1;
      const isAndroid = ua.indexOf("android") > -1;
      const isIOS = /(iphone|ipad|ipod|ios)/i.test(ua);

      let version_code = 0;
      if (isLinux) version_code = 1;
      if (isMac) version_code = 2;
      if (isAndroid) version_code = 3;
      if (isIOS) version_code = 4;
      return version_code;
    },
  },
};
