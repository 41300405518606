<template>
  <div id="app">
    <Header />
    <div>
      <router-view />
    </div>

    <!-- 反馈弹框 -->
    <FeedBack></FeedBack>
  </div>
</template>

<script>
// 头部
import Header from './components/Header/Header.vue'
import FeedBack from './components/FeedBack/feedBack'
export default {
  name: 'App',
  
  components: {
    Header,
    FeedBack
  },
}
</script>


<style lang="less">
// 去除浏览器滚动条
// html { 
//   -ms-overflow-style: none; 
//   overflow: -moz-scrollbars-none;
//   scrollbar-width: none;
// }
// html::-webkit-scrollbar{
//   width: 0px;
// }
//去除dialog遮罩层给body设置的内边距
body.el-popup-parent--hidden {
  padding-right: 0px !important;
  // overflow: hidden; 
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
