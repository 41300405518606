const version_types = {
  win32: 0,
  win64: 1,
  mac: 2,
  Debin: 3,
  RHEL: 4,
  macM1: 5,
  DebianARM: 6,
  RHELARM: 7,
  Android: 8,
  IOS: 9,
};

export default {
  data() {
    return {
      win32Download_url: "", //version_type为0
      win64Download_url: "", //version_type为1
      macDownload_url: "", //version_type为2
      DebinDownload_url: "", //version_type为3
      RHELDownload_url: "", //version_type为4
      macM1Download_url: "", // version_type为5
      DebianARMDownload_url: "", // version_type为6
      AndroidDownload_url: "", // version_type为8
      IOSDownload_url: "", // version_type为9
      win32Code: "",
      win64Code: "",
      DebinCode: "",
      RHELCode: "",
      macCode: "",
      macM1Code: "",
      DebianARMCode: "",
      AndroidCode: "",
      IOSCode: "",
    };
  },
  methods: {
    //检查更新
    update() {
      //win版本
      let data = {};
      data.version_code = "";
      data.resource_code = "";
      data.isWeb = 0;
      data.version_type = "";
      this.$api.checkApp(data).then((res) => {
        let { code, data } = res.data;
        if (code === 0) {
          for (let i = 0; i < data.length; i++) {
            let { version_type, download_url, version_code } = data[i];
            if (version_type === version_types.win32) {
              this.win32Download_url = download_url;
              this.win32Code = version_code;
              this.isWinShow = true;
            } else if (version_type === version_types.win64) {
              this.win64Download_url = download_url;
              this.win64Code = version_code;
              this.isWinShow = true;
            } else if (version_type === version_types.mac) {
              this.macDownload_url = download_url;
              this.macCode = version_code;
              this.isMacShow = true;
            } else if (version_type === version_types.Debin) {
              this.DebinDownload_url = download_url;
              this.DebinCode = version_code;
              this.isLinShow = true;
            } else if (version_type === version_types.RHEL) {
              this.RHELDownload_url = download_url;
              this.RHELCode = version_code;
              this.isLinShow = true;
            } else if (version_type === version_types.macM1) {
              this.macM1Download_url = download_url;
              this.macM1Code = version_code;
              this.isMacShow = true;
            } else if (version_type === version_types.DebianARM) {
              this.DebianARMDownload_url = download_url;
              this.DebianARMCode = version_code;
              this.isLinShow = true;
            } else if (version_type === version_types.Android) {
              this.AndroidDownload_url = download_url;
              this.AndroidCode = version_code;
              this.isAndroidShow = true;
            } else if (version_type === version_types.IOS) {
              this.IOSDownload_url = download_url;
              this.IOSCode = version_code;
              this.isIOSShow = true;
            }
          }
        }
      });
    },

    /**
     * 使用https协议下载
     * @param {String} url
     */
    httpsDownload(url) {
      url = url.split(":");
      for (let i = 0; i < url.length; i++) {
        if (url[0] != "https") {
          url[0] = "https";
        }
      }
      url = url.join(":");
      window.location.href = url;
    },

    /**
     * 判断是否是safari浏览器
     * @returns
     */
    isSafari() {
      return (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      );
    },

    /**
     * 统计下载次数
     * @param {Number} version_type
     * @param {Number} version_code
     */
    getDownNum(version_type, version_code) {
      let data = {};
      data.version_type = version_type;
      data.version_code = version_code;
      this.$api.statistic(data).then(() => {});
    },

    /**
     * 下载windows32 0
     */
    async win32dowsDown() {
      let url = this.win32Download_url;
      if (url === "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        this.httpsDownload(url);
        await this.getDownNum(version_types.win32, this.win32Code);
      }
      this.winVisible = false;
      this.winVisible2 = false;
    },
    /**
     * 下载windows64 1
     */
    async win64dowsDown() {
      let url = this.win64Download_url;
      this.httpsDownload(url);
      await this.getDownNum(version_types.win64, this.win64Code);
      this.winVisible = false;
      this.winVisible2 = false;
    },

    /**
     * 下载Mac版本 2
     */
    async macdowsDown() {
      let url = this.macDownload_url;
      if (url === "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        this.httpsDownload(url);
        await this.getDownNum(version_types.mac, this.macCode);
      }
      this.macVisible = false;
      this.macVisible2 = false;
    },
    /**
     * 下载Debinlinux 3
     */
    async DebinDown() {
      let url = this.DebinDownload_url;
      this.httpsDownload(url);
      await this.getDownNum(version_types.Debin, this.DebinCode);
      this.linuxVisible = false;
      this.linuxVisible2 = false;
    },
    /**
     * 下载RHELlinux 4
     */
    async RHELDown() {
      let url = this.RHELDownload_url;
      if (url == "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        this.httpsDownload(url);
        await this.getDownNum(version_types.RHEL, this.RHELCode);
        this.linuxVisible = false;
        this.linuxVisible2 = false;
      }
    },
    /**
     * 下载Mac_M1版本 5
     */
    async macM1dowsDown() {
      let url = this.macM1Download_url;
      if (url === "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        this.httpsDownload(url);
        await this.getDownNum(version_types.macM1, this.macM1Code);
      }
      this.macVisible = false;
      this.macVisible2 = false;
    },

    /**
     * 下载Debian ARM版本 6
     */
    async DebianARMDown() {
      let url = this.DebianARMDownload_url;
      if (url === "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        this.httpsDownload(url);
        await this.getDownNum(version_types.DebianARM, this.DebianARMCode);
      }
      this.linuxVisible = false;
      this.linuxVisible2 = false;
    },

    /**
     * 下载Android版本 8
     */
    async AndroidDown() {
      let url = this.AndroidDownload_url;
      if (url === "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        this.httpsDownload(url);
        await this.getDownNum(version_types.Android, this.AndroidCode);
      }
      this.androidVisiable = false;
    },

    /**
     * 下载IOS版本 9
     */
    async IOSDOWN() {
      let url = this.IOSDownload_url;
      if (url === "") {
        this.$message({
          showClose: true,
          message: "敬请期待",
        });
      } else {
        // 需要先判断是否是safari浏览器, 不是则要引导使用safari浏览器打开
        if (!this.isSafari()) {
          this.$alert("请使用safari浏览器下载", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
        window.open(url);
        await this.getDownNum(version_types.IOS, this.IOSCode);
        this.iosVisiable = false;
      }
    },
  },
};
