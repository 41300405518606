<template>
  <div class="about">
    <img class="intro-img" src="../assets/about/banner.jpg" alt="" />

    <div class="wrapper">
      <!-- 侧边栏 -->
      <div class="aside" ref="asideWrapper">
        <div class="container" ref="aside">
          <h2>Aechoterm简介</h2>
          <h3
            v-for="(item, index) in titles"
            ref="title"
            :key="index"
            :class="{ active: index === currentIndex }"
            @click="titleClick(index)"
          >
            {{ item }}
          </h3>
        </div>
      </div>
      <!-- 右边内容区域 -->
      <div class="main" ref="main">
        <p>
          Aechoterm 是一款免费的、跨平台的，以SSH、SFTP协议访问远程服务器的终端、文件管理工具。可以将本地与远程主机文件进行互传，并可在多个远程服务器上同时执行命令
        </p>
        <div>
          <h3 class="title" @click="titleClick(-1)">基本特点</h3>
          <p>
            完全免费，风格简约，支持中文，自带sftp，安全稳定，Windows/macOS/Linux多平台可用。
          </p>
        </div>

        <div>
          <h3 class="title" @click="titleClick(0)">创建SSH会话</h3>
          <p>
            可使用密码或密钥登录。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/createSSH.gif')"
              alt="创建SSH会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(1)">
            使用代理登录
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/useProxyLogin.gif')"
              alt="使用代理登陆"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(2)">
            设置终端编码
          </h3>
          <p>新建或编辑会话时，可设置终端编码。</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/setCode.gif')"
              alt="设置终端编码"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(3)">
            设置会话分组
          </h3>
          <p>新建或编辑会话时，可新增或更改会话分组。</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/setSessionGroups.gif')"
              alt="设置会话分组"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(4)">
            会话拖拽排序
          </h3>
          <p>上下拖拽会话，不仅可以改变会话顺序，也可快速更改会话分组。</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/sessionOrder.gif')"
              alt="会话拖拽排序"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(5)">
            保存会话
          </h3>
          <p>
            若新建或编辑会话时，选择保存会话，在下次开启终端时，可快速打开已保存的会话。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/saveSession.gif')"
              alt="保存会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(6)">
            会话列表管理
          </h3>
          <p>
            会话列表可展开或收起；<br />
            使用关键字来快速搜索会话；
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/manageSession.gif')"
              alt="会话列表管理"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
          <p>使用鼠标右键点击会话，以实现：</p>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(7)">
            编辑、删除或重命名会话
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/operateSession.gif')"
              alt="编辑、删除或重命名会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(8)">
            克隆会话
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/cloneSession.gif')"
              alt="克隆会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(9)">
            标记会话颜色
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/markSessionColor.gif')"
              alt="标记会话颜色"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(10)">
            会话导出
          </h3>
          <p>
            鼠标右键选中会话（或组），可单独（或分组）导出会话；<br />
            若鼠标右键点击在会话列表的空白部分，可以选择导出全部会话；
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/exportSession.gif')"
              alt="会话导出"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(11)">
            会话导入
          </h3>
          <p>在创建里选择从本地文件中导入会话到终端。</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/importSession.gif')"
              alt="会话导入"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(12)">
            会话标签管理
          </h3>
          <p>
            可同时打开多个会话；<br />
            使用鼠标右键选中某个会话标签，以实现：
          </p>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(13)">
            关闭会话
          </h3>
          <p>可快速关闭当前会话、其他会话或所有会话</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/closeSession.gif')"
              alt="关闭会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(14)">
            复制会话
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/copySession.gif')"
              alt="复制会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(15)">
            在新窗口中打开会话
          </h3>
          <p>在新窗口打开某个会话，可以实现单个会话的全屏模式。</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/openNewSession.gif')"
              alt="在新窗口中打开会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(16)">
            会话窗口管理
          </h3>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(17)">
            会话平铺
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/tileSession.gif')"
              alt="会话平铺"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(18)">
            导出会话日志
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/exportSessionLog.gif')"
              alt="导出会话日志"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(19)">
            查找会话内容
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="
                require('../assets/about/pageImage/searchSessionContent.gif')
              "
              alt="查找会话内容"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(20)">
            清屏
          </h3>
          <p>如果在设置中关闭快捷复制粘贴，还可以通过鼠标右键来选择清屏。</p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/clearScreen.gif')"
              alt="清屏"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(21)">
            会话分屏
          </h3>
          <p>
            支持横向及纵向分屏；<br />
            分屏大小可自由调整。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/sessionSplitScreen.gif')"
              alt="会话分屏"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(22)">
            批量输入
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/batchInput.gif')"
              alt="批量输入"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(23)">
            快捷命令
          </h3>
          <p>
            在导航栏的快捷命令中来新增和管理快捷命令；<br />
            在会话窗口的右下方来执行已保存的快捷命令。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/quickCommand.gif')"
              alt="快捷命令"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(24)">
            服务器监控
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/serverMonitor.gif')"
              alt="服务器监控"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(25)">
            SFTP
          </h3>
          <p>
            文件可视化管理，可拖拽上传或下载文件；<br />
            可查看传输进度，取消传输中文件；<br />
            支持使用资源管理器打开本地文件。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/SFTP.gif')"
              alt="SFTP"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(26)">
            内置编辑器
          </h3>
          <p>
            远程服务器上的文本文件，可直接双击打开，并编辑。<br />
            支持大部分编程文件格式，以及配置文件格式的语法高亮。<br />
            可使用Ctrl+S（或Command+S）快捷键保存；Ctrl+鼠标滚轮可调整字体大小。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/builtInEditor.gif')"
              alt="内置编辑器"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(27)">
            云同步
          </h3>
          <p>
            使用微信扫码或手机验证码登录后，可同步会话信息、设置信息及快捷命令。<br />
            ps:
            自定义密钥不会上传到服务器，请牢记您填写的密钥，否则将无法解密同步内容。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/cloudSync.gif')"
              alt="云同步"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(28)">
            支持sz&nbsp;rz
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/supportSZRZ.gif')"
              alt="支持SZRZ"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(29)">
            设置
          </h3>
          <p>
            可进行主题、字体、行距、终端类型、开启或关闭增强下载等基础设置。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/settings.gif')"
              alt="设置"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(30)">
            快捷方式
          </h3>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(31)">
            使用快捷复制粘贴
          </h3>
          <p>
            在设置中打开或关闭快捷复制粘贴（默认开启）；<br />
            打开快捷复制粘贴时，将自动复制选中的会话内容，使用鼠标右键来粘贴；<br />
            当关闭快捷复制粘贴时，需使用鼠标右键来复制或粘贴。
          </p>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/copyPaste.gif')"
              alt="使用快捷复制粘贴"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(32)">
            使用Ctrl+tab快捷切换会话
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/switchSession.gif')"
              alt="使用Ctrl+tab快捷切换会话"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title small" @click="titleClick(33)">
            使用Ctrl+鼠标滚轮缩放终端字体大小
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="require('../assets/about/pageImage/changeFontSize.gif')"
              alt="使用Ctrl+鼠标滚轮缩放终端字体大小"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(34)">
            创建本地终端
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="
                require('../assets/about/pageImage/createLocalTerminal.gif')
              "
              alt="创建本地终端"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>

        <div>
          <h3 class="title" @click="titleClick(35)">
            检查更新及反馈
          </h3>
          <div class="img">
            <el-image
              @load="imgLoad"
              :src="
                require('../assets/about/pageImage/checkUpdateAndFeedback.gif')
              "
              alt="检查更新及反馈"
            >
              <div
                slot="placeholder"
                class="image-slot"
                v-loading="true"
                element-loading-text="拼命加载中"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              ></div>
            </el-image>
          </div>
        </div>
      </div>
    </div>

    <!-- 回到顶部按钮 -->
    <BackTop></BackTop>
    <!-- 底部页脚栏 -->
    <Footer ref="footer"></Footer>
  </div>
</template>

<script>
// import Header from '../components/Header/Header.vue'
import Footer from "../components/Footer/footer.vue";
import BackTop from "../components/BackTop/backTop.vue";

export default {
  name: "About",
  data() {
    return {
      titles: [
        "创建SSH会话",
        "使用代理登录",
        "设置终端编码",
        "设置会话分组",
        "会话拖拽排序",
        "保存会话",
        "会话列表管理",
        "编辑、删除或重命名会话",
        "克隆会话",
        "标记会话颜色",
        "会话导出",
        "会话导入",
        "会话标签管理",
        "关闭会话",
        "复制会话",
        "在新窗口中打开会话",
        "会话窗口管理",
        "会话平铺",
        "导出会话日志",
        "查找会话内容",
        "清屏",
        "会话分屏",
        "批量输入",
        "快捷命令",
        "服务器监控",
        "SFTP",
        "内置服务器",
        "云同步",
        "支持sz rz",
        "设置",
        "快捷方式",
        "使用快捷复制粘贴",
        "使用Ctrl+tab快捷切换会话",
        "使用Ctrl+鼠标滚轮缩放终端字体大小",
        "创建本地终端",
        "检查更新及反馈",
      ],
      currentIndex: -1, //记录左侧栏当前点击的下标，默认-1是为了防止刷新页面时选中状态和滚动位置不一致的问题
      offsetTops: [], //记录内容区域每个h3标题的offsetTop,方便点击跳转
    };
  },
  components: {
    // Header,
    Footer,
    BackTop,
  },
  methods: {
    //标题点击
    titleClick(index) {
      //侧边栏点击，页面滚动至内容区域相应标题的元素上
      window.scrollTo({
        top: this.offsetTops[index + 1] - 80, //减掉顶部导航栏的高度
        // behavior: "smooth"
      });
      // this.currentIndex = index
    },
    // 监听滚动事件
    async scrollAboutListener() {
      await this.$nextTick();
      const scrollTop = Math.ceil(
        window.pageYOffset ||
          document.body.scrollTop ||
          document.documentElement.scrollTop
      );
      const aside = this.$refs.aside;
      const asideWrapper = this.$refs.asideWrapper;
      const main = this.$refs.main;
      const mainScrollHeight = main.scrollHeight;
      const img = document.getElementsByClassName("intro-img")[0]; //用户获取用户手册图片的高度
      // const container = this.$refs.container
      //当滚动条进行滚动时，侧边栏的定位进行改变，滚动条滚动距离>=用户手册图片高度时，将侧边栏设置为固定定位
      if (scrollTop >= img.scrollHeight) {
        aside.setAttribute("style", "position: fixed");
        // aside.setAttribute("style", "position: absolute; left: 0; top:"+ (scrollTop - img.scrollHeight) + "px;")
        // 当滚动到(内容区域高度+用户手册高度-侧边栏高度时)将侧边栏固定，防止其遮挡底部
        if (
          scrollTop >
          mainScrollHeight + img.scrollHeight - asideWrapper.scrollHeight
        ) {
          aside.setAttribute(
            "style",
            "position: absolute; left: 0; top:" +
              (mainScrollHeight - asideWrapper.scrollHeight) +
              "px"
          );
          this.currentIndex = this.titles.length - 1;
        }
      } else {
        aside.setAttribute("style", "");
      }

      //监听滚动距离：当滚动条到达相应的h3元素的offsetTop - 80（顶部导航栏的高度）时，侧边栏选中状态改变
      const offsetTops = this.offsetTops.map((item) => {
        return item - 80;
      });

      // 根据滚动条滚动的距离来设置侧边栏的选中状态
      for (let i = 2; i <= offsetTops.length - 1; i++) {
        if (i == offsetTops.length - 1) {
          //对应最后一个选中状态
          this.currentIndex = i - 1;
        }
        if (scrollTop < offsetTops[i]) {
          this.currentIndex = i - 2;
          // 侧边栏联动滚动：当前选中标题距离顶部距离小于侧边栏滚动距离 或者 当前选中标题距离顶部距离大于侧边栏滚动距离加上侧边栏高度时 侧边栏联动滚动
          let asideScrollTop = aside.pageYOffset || aside.scrollTop; //侧边栏滚动距离
          if (
            this.$refs.title[this.currentIndex].offsetTop < asideScrollTop ||
            asideScrollTop + asideWrapper.scrollHeight <
              this.$refs.title[this.currentIndex].offsetTop
          ) {
            this.$refs.aside.scrollTo({
              top: this.$refs.title[this.currentIndex].offsetTop,
            });
          }
          break;
        }
      }
      // 页面滚动距离小于第一个h3标签时侧边栏滚动到顶
      if (scrollTop <= offsetTops[0]) {
        this.$refs.aside.scrollTo({
          top: 0,
        });
      }
    },

    //图片加载完成回调函数:当所有图片加载完成时才计算内容区域中定位元素h3的offsetTop X
    //图片加载完成回调函数:每加载一张图片重新计算高度
    imgLoad() {
      this.offsetTops = [];
      const h3 = this.$refs.main.getElementsByTagName("h3");
      h3.forEach((item) => {
        this.offsetTops.push(item.offsetTop);
      });
    },
  },
  mounted() {
    this.imgLoad();
    this.scrollAboutListener();
    window.addEventListener("scroll", this.scrollAboutListener);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollAboutListener);
  },
};
</script>

<style lang="less" scoped>
html {
  font-size: 12px;
}
h2,
h3,
p {
  padding: 0;
  margin: 0;
  font-weight: 400;
  // font-family: PingFangSC-Regular;
  cursor: default;
}
h3 {
  line-height: 22px;
  cursor: pointer;
}
.about {
  width: 100%;
  background: #f7f7f7;
  min-width: 980px;
  .intro-img {
    width: 100%;
    height: 320px;
    display: block; //消除图片底部的留白
  }
  .wrapper {
    width: 960px;
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    .aside {
      width: 240px;
      height: 526px;
      position: relative;
      .container {
        width: 240px;
        height: 526px;
        top: 120px;
        background-color: #e6eaf1;
        overflow: hidden;
        overflow-y: scroll;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
      }
      .container::-webkit-scrollbar {
        display: none;
      }
      h2 {
        font-size: 18px;
        padding: 26px 0 26px 26px;
        font-weight: bold;
      }
      h3 {
        padding: 9px 0 9px 26px;
      }
      .active {
        background-color: #d8dee9;
      }
    }
    .main {
      width: 694px;
      p {
        margin-bottom: 26px;
      }
      > p:nth-of-type(1) {
        margin-bottom: 37px;
      }
      div {
        margin-bottom: 26px;
      }
      div.img {
        width: 690px;
      }

      div:nth-child(1) .title {
        margin-top: 37px;
      }
      .title {
        margin-bottom: 26px;
        color: #3e7aec;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
      }
      .small {
        font-weight: 400;
      }
      img {
        //图片等比例缩放
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: block; //去掉图片底部白边
      }
      .img {
        .image-slot {
          width: 690px;
          height: 465px;
        }
        /deep/.el-loading-mask {
          z-index: 200;
        }
        /deep/img {
          width: 690px;
          height: 456px;
        }
      }
    }
  }
}
</style>
