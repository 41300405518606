/* eslint-disable */
import { post, postFile, uploadFile, get } from "./request.js";

const Api = {
  // 登录
  login(params) {
    return post("login", "login", params);
  },
  // 获取图形验证码
  getCode() {
    return post("code", "code001");
  },
  // 获取手机验证码
  getPhoneCode(params) {
    return post("txCtrl", "msg001", params);
  },

  // 新增用户
  addUser(params) {
    return post("txCtrl", "user001", params);
  },

  checkApp(params) {
    return post("txCtrl", "ec009", params);
  },

  addfeedBack(params) {
    return post("txCtrl", "ec010", params);
  },
  statistic(params) {
    return post("txCtrl", "ec011", params);
  },

  upLoadImg(params) {
    return uploadFile("upload", "ec012", params);
  },

  // 获取更新日志数据
  getUpdateLogData(params) {
    return post("txCtrl", "ec017", params);
  },
};

export default Api;
