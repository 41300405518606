<template>
  <div>
    <h1 style="display:none">免费 安全 稳定 便携的SSH连接工具</h1>
    <div class="home">
      <div class="img">
        <!-- <div class="feedback">
          <el-button type="text"
                     class="btnStyle"
                     @click="openFeedBack">反馈</el-button>
        </div> -->
        <!-- 引入的头部 -->
        <!-- <Header/> -->
      </div>

      <div class="centerBox">
        <div class="header">
          <div class="headerbox">
            <h1 class="logoTest">免费 安全 稳定 便携的SSH连接工具</h1>
            <div class="testBox">
              <h2 class="smallTest">随时监控系统状态</h2>
              <h2 class="smallTest">轻松管理文件</h2>
              <h2 class="smallTest">连接服务器就用 <span>Aechoterm</span></h2>
            </div>

            <div class="sysTab">
              <div class="firstBox" v-if="isLinShow">
                <Download
                  v-model="linuxVisible"
                  title="linux&信创"
                  leftText="麒麟/统信等Debian AMD架构"
                  rightText="麒麟/统信等Debian ARM架构"
                  :btnType="'2'"
                  @leftDown="DebinDown"
                  @rightDown="DebianARMDown"
                >
                  <template>
                    <div class="imgBox"></div>
                    <div class="boxtext">Linux&信创</div>
                  </template>
                </Download>
              </div>
              <div class="secondBox" v-if="isWinShow">
                <Download
                  v-model="winVisible"
                  title="win"
                  leftText="32位"
                  rightText="64位"
                  :btnType="'1'"
                  @leftDown="win32dowsDown"
                  @rightDown="win64dowsDown"
                >
                  <template>
                    <div class="imgBox"></div>
                    <div class="boxtext">Windows</div>
                  </template>
                </Download>
              </div>
              <div class="thirdBox" v-if="isMacShow">
                <Download
                  v-model="macVisible"
                  title="mac"
                  leftText="Mac"
                  rightText="Mac_M1"
                  :btnType="'1'"
                  @leftDown="macdowsDown"
                  @rightDown="macM1dowsDown"
                >
                  <template>
                    <div class="imgBox"></div>
                    <div class="boxtext">MacOS</div>
                  </template>
                </Download>
              </div>

              <div class="fourdBox">
                <!-- <div>
                  <div class="imgBox"></div>
                  <div class="boxtext">Android</div>
                </div>
                <div class="desc">敬请期待</div> -->
                <Download
                  v-model="androidVisiable"
                  title="Android"
                  leftText="Android系统"
                  :btnType="'2'"
                  @leftDown="AndroidDown"
                >
                  <template>
                    <div class="imgBox"></div>
                    <div class="boxtext">Android</div>
                  </template>
                </Download>
              </div>
              <div class="fiveBox">
                <!-- <div>
                  <div class="imgBox"></div>
                  <div class="boxtext">iphone</div>
                </div>
                <div class="desc">敬请期待</div> -->
                <Download
                  v-model="iosVisiable"
                  title="IOS"
                  leftText="IOS系统"
                  :btnType="'2'"
                  @leftDown="IOSDOWN"
                >
                  <template>
                    <div class="imgBox"></div>
                    <div class="boxtext">IOS</div>
                  </template>
                </Download>
              </div>
            </div>
          </div>
        </div>
        <div class="securityBox">
          <div class="firstSecurity">
            <div class="firstSecurityImg"></div>
            <div class="firstSecuritytext">安全加密</div>
            <div class="firstSecuritydetial">保护服务器安全</div>
          </div>
          <div class="secondSecurity">
            <div class="secondSecurityImg"></div>
            <div class="secondSecuritytext">轻松管理</div>
            <div class="secondSecuritydetial">可视化文件编辑</div>
          </div>
          <div class="thirdSecurity">
            <div class="thirdSecurityImg"></div>
            <div class="thirdSecuritytext">便捷监控</div>
            <div class="thirdSecuritydetial">数据实时同步</div>
          </div>
        </div>

        <!-- 进度条介绍区 -->
        <div class="introduce">
          <div class="left">
            <div class="leftItem" @click="toOneProgress(1)">
              <div class="title">远程SSH连接</div>
              <div class="tip">支持用户名密码或者用户名密钥登陆远程服务器</div>
              <el-progress
                :percentage="progress1"
                :format="format"
                :stroke-width="2"
              ></el-progress>
            </div>
            <div class="leftItem" @click="toOneProgress(2)">
              <div class="title">快捷命令</div>
              <div class="tip">
                在弹窗左侧输入常用命令，保存即可将命令保存到右侧的命令列表
              </div>
              <el-progress
                :percentage="progress2"
                :format="format"
                :stroke-width="2"
              ></el-progress>
            </div>
            <div class="leftItem" @click="toOneProgress(3)">
              <div class="title">批量输入</div>
              <div class="tip">
                在会话终端界面下方，输入命令并批量执行到所有开启的会话终端上
              </div>
              <el-progress
                :percentage="progress3"
                :format="format"
                :stroke-width="2"
              ></el-progress>
            </div>
            <div class="leftItem" @click="toOneProgress(4)">
              <div class="title">云同步</div>
              <div class="tip">
                将会话信息、设置信息、快捷命令分别上传至云服务器。避免丢失会话信息和需要在不同设备上使用软件带来创建会话的繁琐。
              </div>
              <el-progress
                :percentage="progress4"
                :format="format"
                :stroke-width="2"
              ></el-progress>
            </div>
          </div>
          <div class="right">
            <img
              :src="currentProgressImg.url"
              :title="currentProgressImg.title"
              :alt="currentProgressImg.alt"
            />
            <!-- <div class="bgDiv"></div> -->
          </div>
        </div>

        <!-- 轮播 -->
        <!-- <div class="video">
          <el-carousel :interval="4000"
                       height="450px">
            <el-carousel-item v-for="item in imgList"
                              :key="item.id">
              <img alt="ssh工具"
                   :src="item.idView"
                   class="image" />
            </el-carousel-item>
          </el-carousel>
        </div> -->

        <!-- 视频区 -->
        <div class="video">
          <!-- <div class="videoPlayKey"></div> -->
          <!-- <div class="videoPart"></div> -->
          <my-video
            :sources="video.sources"
            :options="video.options"
          ></my-video>
        </div>

        <div class="functionpart">
          <div class="functionbox">
            <div class="functionitem">
              <div class="fileitem"></div>
              <div class="filetext">可视化文件编辑</div>
            </div>
            <div class="onlinefileitem">
              <div class="fileitem"></div>
              <div class="filetext">在线文本编辑</div>
            </div>
            <div class="performanceitem">
              <div class="fileitem"></div>
              <div class="filetext">性能监控</div>
            </div>
          </div>
          <div class="secfunbox">
            <div class="downloaditem">
              <div class="fileitem"></div>
              <div class="filetext">文件下载</div>
            </div>
            <div class="memoryitem">
              <div class="fileitem"></div>
              <div class="filetext">内存分配</div>
            </div>
            <div class="handDiskitem">
              <div class="fileitem"></div>
              <div class="filetext">硬盘消耗</div>
            </div>
          </div>
          <div class="thirdfunbox">
            <div class="processitem">
              <div class="fileitem"></div>
              <div class="filetext">按需设置秘钥密码，时刻安全防护连接数据</div>
            </div>
            <div class="cpuitem">
              <div class="fileitem"></div>
              <div class="filetext">CPU使用率</div>
            </div>
          </div>
        </div>

        <!-- 知识社区 -->
        <!-- <div class="KlCommunity">
          <h3 class="klHeader">知识社区</h3>
          <div class="klBox">
            <div class="klItem"
                 v-for="item in klList"
                 :key="item.id">
              <div class="leftImg">
                <img :src="item.url"
                     alt="">
              </div>
              <div class="rightContent">
                <div class="itemTitle">{{item.title}}</div>
                <div class="itemTip">{{item.tip}}</div>
                <a href="">继续阅读</a>
              </div>
            </div>
          </div>
          <div class="moreKl">
            <button class="klBtn"
                    @click="moreBtn('community')">更多知识</button>
          </div>
        </div> -->

        <!-- 更新日志 -->
        <div class="KlCommunity" style="marginTop: 32px">
          <h3 class="klHeader">更新日志</h3>
          <div class="klBox updateLog">
            <div
              class="u-wrapper"
              v-for="(uData, index) in updateLogData"
              :key="index"
            >
              <div class="u-header">
                <div class="version-code">
                  <span>V{{ uData.version_code }}</span>
                </div>
                <div class="modify-date">
                  <span>{{ uData.create_date }}</span>
                </div>
              </div>
              <div class="u-content">
                <pre>{{ uData.version_detail }}</pre>
              </div>
            </div>
          </div>
          <div class="moreKl">
            <button class="klBtn" @click="moreBtn('updateLog')">
              查看更多
            </button>
          </div>
        </div>

        <!-- 最新版 -->
        <div class="latest-version">
          <h3 class="title">
            ——&nbsp;&nbsp;&nbsp;&nbsp;立即下载最新版&nbsp;&nbsp;&nbsp;&nbsp;——
          </h3>
          <div class="sysTab">
            <div class="st-wrapper">
              <Download
                v-model="linuxVisible2"
                title="linux&信创"
                leftText="麒麟/统信等Debian AMD架构"
                rightText="麒麟/统信等Debian ARM架构"
                :btnType="'2'"
                @leftDown="DebinDown"
                @rightDown="DebianARMDown"
              >
                <template>
                  <img src="../assets/linux2.png" alt="" />
                </template>
              </Download>
              <div>linux&信创</div>
            </div>
            <div class="st-wrapper">
              <Download
                v-model="winVisible2"
                title="win"
                leftText="32位"
                rightText="64位"
                :btnType="'1'"
                @leftDown="win32dowsDown"
                @rightDown="win64dowsDown"
              >
                <template>
                  <img src="../assets/windows2.png" alt="" />
                </template>
              </Download>
              <div>Windows</div>
            </div>
            <div class="st-wrapper">
              <Download
                v-model="macVisible2"
                title="mac"
                leftText="Mac"
                rightText="Mac_M1"
                :btnType="'1'"
                @leftDown="macdowsDown"
                @rightDown="macM1dowsDown"
              >
                <template>
                  <img src="../assets/MacOS2.png" alt="" />
                </template>
              </Download>
              <div>MacOS</div>
            </div>
            <div class="st-wrapper">
              <Download
                v-model="androidVisiable2"
                title="Android"
                leftText="Android系统"
                :btnType="'2'"
                @leftDown="AndroidDown"
              >
                <template>
                  <img src="../assets/android2.png" alt="" />
                </template>
              </Download>
              <div>Android</div>
            </div>
            <div class="st-wrapper">
              <Download
                v-model="iosVisiable2"
                title="IOS"
                leftText="IOS系统"
                :btnType="'2'"
                @leftDown="IOSDOWN"
              >
                <template>
                  <img src="../assets/MacOS2.png" alt="" />
                </template>
              </Download>
              <div>MacOS</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 引入的底部 -->
      <Footer />

      <!-- 置顶按钮 -->
      <BackTop />

      <!-- <footer class="footer">
        <div class="footertext">
          Copyright © 2021广州南天电脑系统有限公司 | 数据智能产品中心
        </div>
      </footer> -->
    </div>
  </div>
</template>

<script>
import ajaxUrl from "../api/request.js";

import Footer from "../components/Footer/footer.vue"; // 底部
import BackTop from "../components/BackTop/backTop.vue"; // 置顶
import Download from "../components/Download/Download.vue"; // 下载弹出框

import downloadMixins from "../mixins/download.js";
import getOSInfoMixins from "../mixins/getOSInfo.js"; // 获取操作系统的信息
import myVideo from "vue-video";

export default {
  name: "Home",
  components: { Footer, BackTop, Download, myVideo },
  mixins: [downloadMixins, getOSInfoMixins],
  data() {
    return {
      isLinShow: true,
      isWinShow: true,
      isMacShow: true,
      isAndroidShow: true,
      isIOSShow: true,
      imgList: [
        { id: 0, idView: require("../assets/ssh_Aechoterm/rollBanner.gif") },
        { id: 1, idView: require("../assets/ssh_Aechoterm/rollBanner.gif") },
        { id: 2, idView: require("../assets/ssh_Aechoterm/rollBanner.gif") },
        { id: 3, idView: require("../assets/ssh_Aechoterm/rollBanner.gif") },
      ],
      limit: 3,
      uploadUrl: ajaxUrl + "/txCtrl?txcode=upFile",
      // headers: { Authorization: "Bearer " + getToken() },
      // hideUploadAdd: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      isShowUpload: true, //展示上传按钮
      labelPosition: "top",
      //linux提示弹框显示
      linuxVisible: false,
      linuxVisible2: false,
      //win提示框显示
      winVisible: false,
      winVisible2: false,
      //mac提示框显示
      macVisible: false,
      macVisible2: false,
      //
      androidVisiable: false,
      androidVisiable2: false,
      iosVisiable: false,
      iosVisiable2: false,
      Visiable: false,
      // 知识社区 展现的内容
      klList: [
        {
          id: 1,
          title: "远程SSH连接",
          tip:
            "支持用户名密码或者用户名密钥登陆远程服务器。可根据个人习惯，对会话进行分组，分组名称可以选择之前已有分组，也可自定义输入。点击测试连接按钮可根据输入的会话信息，测试输入内容是否正确。",
          url: require("../assets/video.png"),
        },
        {
          id: 2,
          title: "远程SSH连接",
          tip:
            "支持用户名密码或者用户名密钥登陆远程服务器。可根据个人习惯，对会话进行分组，分组名称可以选择之前已有分组，也可自定义输入。点击测试连接按钮可根据输入的会话信息，测试输入内容是否正确。",
          url: require("../assets/video.png"),
        },
        {
          id: 3,
          title: "远程SSH连接",
          tip:
            "支持用户名密码或者用户名密钥登陆远程服务器。可根据个人习惯，对会话进行分组，分组名称可以选择之前已有分组，也可自定义输入。点击测试连接按钮可根据输入的会话信息，测试输入内容是否正确。",
          url: require("../assets/video.png"),
        },
      ],
      // 定义一个进度条的定时器
      progressTimer: null,
      // 滚动条 每60秒实现切换下一个进度条 被点击了 就重新开始计算
      progress1: 0,
      progress2: 0,
      progress3: 0,
      progress4: 0,
      // 进度条右边的图片
      currentProgressImg: {},
      progressImg: [
        {
          id: 1,
          title: "远程ssh连接-Aechoterm",
          alt: "远程ssh连接-Aechoterm",
          url: require("../assets/ssh_Aechoterm/RemoteSSH@2x.png"),
        },
        {
          id: 2,
          title: "快捷命令-Aechoterm",
          alt: "快捷命令-Aechoterm",
          url: require("../assets/ssh_Aechoterm/ShortcutCommand@2x.png"),
        },
        {
          id: 3,
          title: "批量输入-Aechoterm",
          alt: "批量输入-Aechoterm",
          url: require("../assets/ssh_Aechoterm/BatchInput@2x.png"),
        },
        {
          id: 4,
          title: "云同步-Aechoterm",
          alt: "云同步-Aechoterm",
          url: require("../assets/ssh_Aechoterm/CloudSynchronization@2x.png"),
        },
      ],
      // 更新日志数据
      updateLogData: [],
      video: {
        sources: [
          {
            src: "https://ec.cnd.nantiangzzx.com/aechoterm20230811-v3.mp4",
            type: "video/mp4",
          },
        ],
        options: {
          volume: 0.6,
          poster: require("../assets/video_cover.jpg"),
        },
      },
    };
  },
  created() {
    this.getUpdateLogData();
  },
  mounted() {
    this.update();
    // 启动页面中的进度条显示 轮播
    this.progressOperation(1);
  },
  methods: {
    // 进度条显示设置
    format() {
      return "";
    },
    // 进度条操作
    progressOperation(current) {
      // 实现右侧图片的切换
      this.changeProgressImg(current);

      // 判断当前页面是否有进度条定时器，有的话，先删除
      if (this.progressTimer) {
        clearInterval(this.progressTimer);
      }
      // 拼接当前的进度条
      let currentProgress = "progress" + current;
      this.progressTimer = setInterval(() => {
        if (this[currentProgress] === 100) {
          // 设置所有进度条进度为0
          this[currentProgress] = 0;
          // 清除当前定时器
          clearInterval(this.progressTimer);

          // 启动下一个进度条
          // 先判断下一个进度条是否为1，是的话，重新开始第一个进度条
          if (current + 1 === 5) {
            this.progressOperation(1);
            return;
          } else {
            // 下一个进度条
            this.progressOperation(current + 1);
            return;
          }
        }
        // 每隔1秒，进度条前进
        // 解决报错问题，不能设置percentage 超过100 0.83-60秒 1.25-40秒
        if (this[currentProgress] >= 100) {
          this[currentProgress] = 100;
        } else {
          // 对本进度条的处理
          this[currentProgress] = this[currentProgress] + 5;
        }
      }, 250);
    },
    // 点击按钮，跳转到指定的进度条
    toOneProgress(progress) {
      // 先设置所有进度条为0
      this.progress1 = 0;
      this.progress2 = 0;
      this.progress3 = 0;
      this.progress4 = 0;

      // 清掉当前的定时器
      if (this.progressTimer) {
        clearInterval(this.progressTimer);
      }

      // 启动当前的进度条
      this.progressOperation(progress);
    },
    // 实现进度条右边图片的切换
    changeProgressImg(current) {
      this.currentProgressImg = this.progressImg[current - 1];
    },
    // 更多知识按钮
    moreBtn(type) {
      //type='community'则是知识社区的更多知识按钮点击，type='updateLog'则是更新日志
      if (type === "community") {
        //知识社区的更多知识按钮点击操作
      } else if (type === "updateLog") {
        //更新日志的查看更多按钮点击操作
        this.$router.push("/log");
      }
    },
    // 获取更新日志数据
    async getUpdateLogData() {
      let params = {};
      params.version_type = this.getOSInfo();
      let { data } = await this.$api.getUpdateLogData(params);
      data = data.data.list;
      // Android和IOS版本不需要增量包
      if (params.version_type == 3 || params.version_type == 4) {
        data = data.filter((item) => {
          return item.file_type === 1;
        });
      }
      data = data.filter((item, index) => {
        return index < 3;
      });
      for (let i = 0; i < data.length; i++) {
        data[i].version_detail = data[i].version_detail.replace(
          /^<br\/>*|<br\/>*$/g,
          ""
        ); //去除头尾<br/>
        data[i].version_detail = data[i].version_detail.replace(
          /(\s+)?<br(\s+)?\/?>(\s+)?/gi,
          "\n"
        ); //<br/>换成\n
        data[i].version_detail = data[i].version_detail.replace(
          /^\s*|\s*$/g,
          ""
        ); //去除头尾空格
      }
      let packageIndex = 0; //标记安装包的下标，file_type:1(安装包)，file_type:2(增量包)
      let Dlength = data.length;
      data.forEach((item, index) => {
        //修改增量包的版本数据
        if (item.file_type === 2) {
          //判断当前安装包是否为增量包，如果是则搜索离它最近的安装包
          for (let i = index + 1; i < Dlength; i++) {
            if (data[i].file_type === 1) {
              packageIndex = i;
              break;
            }
          }
          item.version_code =
            data[packageIndex].version_code + " - " + item.version_code;
        }
      });
      this.updateLogData = data;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  width: 100%;
  min-width: 980px;
  background: #f7f7f7;
  background-size: 100% 100%;
  // background: url("../ ets/background.png") no-repeat;
  .img {
    position: absolute;
    width: 100%;
    height: 698px;
    // background-color: green;
    background: url("../assets/background.png") no-repeat;
    background-size: 100% 100%;
    // z-index: -1;
    // #region
    // .feedback {
    //   // margin-left: 960px;
    //   float: right;
    //   font-size: 15px;
    //   // font-weight: 500;
    //   padding-top: 6px;
    //   padding-right: 20px;
    //   color: #fff;
    //   cursor: pointer;
    //   .btnStyle {
    //     color: #fff;
    //     font-size: 13px;
    //     // font-weight: 700;
    //     border-radius: 10px;
    //     width: 50px;
    //     background: #4780ed;
    //   }
    //   .btnStyle:hover {
    //     // color: black;
    //     background: #4f88f5;
    //   }
    //   // .feedbackBtn {
    //   //   width: 50px;
    //   //   height: 30px;
    //   //   // border: 1px solid #f5f5f5;
    //   //   border: none;
    //   //   border-radius: 10px;
    //   //   color: #fff;
    //   //   font-size: 13px;
    //   //   background: #4780ed;
    //   // }
    //   // #input {
    //   //   width: 220px;
    //   //   border: 0px;
    //   //   background: #3e7aec;
    //   //   font-size: 15px;
    //   //   color: #e6e8ea;
    //   //   cursor: pointer;
    //   // }
    //   // #input:focus {
    //   //   outline: none;
    //   // }
    // }
    // #endregion
  }
  .centerBox {
    width: 960px;
    height: 100%;
    margin: 0 auto;
    // background-color: green;
    // z-index: 3;

    .header {
      position: relative;
      // padding: 10px;
      width: 100%;
      height: 698px;
      // background-color: #f7f7f7;
      // background-color: #3e7aec;

      background-size: 100%;
      .headerbox {
        // position: absolute;
        // top: 135px;
        transform: translateY(135px);
        .logoTest {
          margin-block-start: 0em;
          margin-block-end: 0em;
          text-align: center;
          font-size: 36px;
          color: #fff;
          font-weight: normal;
          // margin: 135px auto 0;
        }

        .testBox {
          // position: absolute;
          margin-top: 30px;
          top: 215px;
          left: 634px;
          .smallTest {
            margin-block-start: 0em;
            margin-block-end: 0em;
            text-align: center;
            margin-bottom: 10px;
            // position: absolute;
            font-size: 18px;
            font-weight: normal;
            color: #fff;
            span {
              color: #50e3c2;
            }
          }
        }

        .sysTab {
          // position: absolute;
          // width: 500px;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          margin-top: 100px;
          top: 407px;
          left: 342px;
          .blankBox {
            width: 150px;
            height: 150px;
          }

          .firstBox {
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background: #4780ed;
            // margin-left: 50px;
            cursor: pointer;
            // padding-top: 36px;
            // padding-left: 56px;
            .imgBox {
              width: 39px;
              height: 47px;
              background: url("../assets/linux.png") no-repeat;
              background-size: 100%;
              margin-top: 36px;
              margin-left: 56px;
            }
            .boxtext {
              margin-top: 12px;
              font-size: 16px;
              color: #fff;
              text-align: center;
            }
          }
          .firstBox:hover {
            background: #4f88f5;
          }
          .secondBox {
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background: #4780ed;
            // margin-left: 50px;
            cursor: pointer;
            // padding-top: 36px;
            // padding-left: 56px;
            .imgBox {
              width: 39px;
              height: 47px;
              background: url("../assets/windows.png") no-repeat;
              background-size: 100%;
              margin-top: 36px;
              margin-left: 56px;
            }
            .boxtext {
              margin-top: 12px;
              font-size: 16px;
              color: #fff;
              text-align: center;
            }
          }
          .secondBox:hover {
            background: #4f88f5;
          }
          .thirdBox {
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background: #4780ed;
            // margin-left: 50px;
            cursor: pointer;
            // padding-top: 36px;
            // padding-left: 56px;
            .imgBox {
              width: 39px;
              height: 47px;
              background: url("../assets/MacOS.png") no-repeat;
              background-size: 100%;
              margin-top: 36px;
              margin-left: 56px;
            }
            .boxtext {
              margin-top: 12px;
              font-size: 16px;
              color: #fff;
              text-align: center;
            }
          }
          .thirdBox:hover {
            background: #4f88f5;
          }
          .fourdBox {
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background: #4780ed;
            overflow: hidden;
            cursor: pointer;
            .imgBox {
              width: 39px;
              height: 47px;
              background: url("../assets/android.png") no-repeat;
              background-size: 100%;
              margin-top: 36px;
              margin-left: 56px;
            }
            .boxtext {
              margin-top: 12px;
              font-size: 16px;
              color: #fff;
              text-align: center;
            }
            .desc {
              text-align: center;
              font-size: font-s12px;
              font-size: 12px;
              color: white;
              /* overflow: hidden; */
              background: hei;
              height: 35px;
              background: rgba(67, 67, 67, 0.408);
              line-height: 30px;
            }
          }
          .fourdBox:hover {
            background: #4f88f5;
          }
          .fiveBox {
            width: 150px;
            height: 150px;
            border-radius: 100px;
            background: #4780ed;
            overflow: hidden;
            cursor: pointer;
            .imgBox {
              width: 39px;
              height: 47px;
              background: url("../assets/MacOS.png") no-repeat;
              background-size: 100%;
              margin-top: 36px;
              margin-left: 56px;
            }
            .boxtext {
              margin-top: 12px;
              font-size: 16px;
              color: #fff;
              text-align: center;
            }
            .desc {
              text-align: center;
              font-size: font-s12px;
              font-size: 12px;
              color: white;
              /* overflow: hidden; */
              background: hei;
              height: 35px;
              background: rgba(67, 67, 67, 0.408);
              line-height: 30px;
            }
          }
          .fiveBox:hover {
            background: #4f88f5;
          }
        }
      }
    }

    .securityBox {
      display: flex;
      // position: absolute;
      // top: 782px;
      // left: 240px;
      // width: 100%;
      // background-color: #f7f7f7;
      margin-top: 84px;
      justify-content: center;
      .firstSecurity {
        position: relative;
        width: 320px;
        height: 220px;
        // background-color: aqua;
        .firstSecurityImg {
          position: absolute;
          top: 53px;
          left: 136px;
          width: 48px;
          height: 56px;
          background: url("../assets/security.png") no-repeat;
        }
        .firstSecuritytext {
          position: absolute;
          font-size: 22px;
          top: 129px;
          left: 116px;
          text-align: center;
        }
        .firstSecuritydetial {
          position: absolute;
          font-size: 12px;
          top: 167px;
          left: 118px;
          text-align: center;
        }
      }
      .firstSecurity:hover {
        border-radius: 16px;
        border: 1px solid #dedede;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
      }
      .secondSecurity {
        position: relative;
        width: 320px;
        height: 220px;
        // background-color: aqua;
        .secondSecurityImg {
          position: absolute;
          top: 53px;
          left: 136px;
          width: 48px;
          height: 56px;
          background: url("../assets/manage.png") no-repeat;
        }
        .secondSecuritytext {
          position: absolute;
          font-size: 22px;
          top: 129px;
          left: 116px;
          text-align: center;
        }
        .secondSecuritydetial {
          position: absolute;
          font-size: 12px;
          top: 167px;
          left: 118px;
          text-align: center;
        }
      }
      .secondSecurity:hover {
        border-radius: 16px;
        border: 1px solid #dedede;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
      }
      .thirdSecurity {
        position: relative;
        width: 320px;
        height: 220px;
        // background-color: aqua;
        .thirdSecurityImg {
          position: absolute;
          top: 53px;
          left: 125px;
          width: 60px;
          height: 56px;
          text-align: center;
          background: url("../assets/monitoring.png") no-repeat;
        }
        .thirdSecuritytext {
          position: absolute;
          font-size: 22px;
          top: 129px;
          left: 112px;
          // width: 100%;
          text-align: center;
        }
        .thirdSecuritydetial {
          position: absolute;
          font-size: 12px;
          top: 167px;
          left: 118px;
          text-align: center;
        }
      }
      .thirdSecurity:hover {
        border-radius: 16px;
        border: 1px solid #dedede;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
      }
    }

    .introduce {
      margin-top: 90px;
      display: flex;
      .left {
        width: 300px;
        .leftItem {
          &:hover {
            cursor: pointer;
          }
          .title {
            margin-top: 25px;
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
          }
          .tip {
            width: 294px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin: 8px 0;
          }
          .el-progress {
            width: 350px;
            height: 2px;
          }
        }
      }
      .right {
        flex: 1;
        margin-left: 78px;
        margin-top: 19px;
        position: relative;
        img {
          position: absolute;
          z-index: 2;
          width: 582px;
          height: 390px;
          // border-radius: 8px;
          // border: 1px solid #3E7AEC;
        }
        .bgDiv {
          position: absolute;
          left: 14px;
          top: 14px;
          z-index: 1;
          width: 568px;
          height: 376px;
          border-radius: 8px;
          // 实现背景颜色渐变和错位显示
          background-color: #3e7aec; /* 浏览器不支持时显示 */
          background-image: linear-gradient(to left bottom, #a7dcff, #3e7aec);
          transition: background-color 0.5s;
        }
      }
    }

    // #region
    // .video {
    //   margin-top: 100px;
    //   width: 960px;
    //   height: 540px;
    //   background: rgba(62, 122, 236, 0.1);
    //   position: relative;
    //   .videoPlayKey {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     margin-top: -50px;
    //     margin-left: -50px;
    //     width: 120px;
    //     height: 120px;
    //     background: url("../assets/play@2x.png") repeat;
    //     background-size:cover;
    //     cursor: pointer;
    //   }
    //   .videoPart {

    //   }
    // }
    // #endregion

    .video {
      .index {
        background: #c8cfd8;
        width: 80%;
        text-align: center;
        margin-left: 142px;
        width: 80.6%;
      }
      .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
      }
      img {
        // background-size: 100%;
        width: 960px;
        height: 550px;
        // width: 100%;
        // height: 100%;
      }
      // position: absolute;
      // top: 1101px;
      // width: 960px;
      // height: 540px;
      // background: salmon;
      // background: url("../assets/video.png");
      // background-size: 100%;
      // transform: translateX(20%);
      margin: 100px auto 0;
    }

    .functionpart {
      // position: absolute;
      // top: 1676px;
      // left: 180px;
      // width: 100%;
      // background: seagreen;
      margin-top: 100px;
      .functionbox {
        display: flex;
        justify-content: center;
        .functionitem {
          width: 300px;
          height: 220px;
          // margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            width: 56px;
            height: 58px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/file.png");
          }
          .filetext {
            font-size: 14px;
            margin-top: 50px;
            text-align: center;
          }
        }
        .onlinefileitem {
          width: 300px;
          height: 220px;
          margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            width: 60px;
            height: 60px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/onlinefile.png");
          }
          .filetext {
            font-size: 14px;
            margin-top: 50px;
            text-align: center;
          }
        }
        .performanceitem {
          width: 300px;
          height: 220px;
          margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            width: 60px;
            height: 60px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/performance.png");
          }
          .filetext {
            font-size: 14px;
            margin-top: 48px;
            text-align: center;
          }
        }
      }
      .secfunbox {
        display: flex;
        justify-content: center;
        .downloaditem {
          width: 300px;
          height: 220px;
          // margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            width: 60px;
            height: 60px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/download.png") no-repeat;
          }
          .filetext {
            font-size: 14px;
            margin-top: 48px;
            text-align: center;
          }
        }

        .memoryitem {
          width: 300px;
          height: 220px;
          margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            width: 60px;
            height: 60px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/memory.png") no-repeat;
            background-size: cover;
          }
          .filetext {
            font-size: 14px;
            margin-top: 48px;
            text-align: center;
          }
        }

        .handDiskitem {
          width: 300px;
          height: 220px;
          margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            width: 60px;
            height: 60px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/handDisk.png");
          }
          .filetext {
            font-size: 14px;
            margin-top: 48px;
            text-align: center;
          }
        }
      }

      .thirdfunbox {
        display: flex;
        justify-content: center;
        .processitem {
          width: 630px;
          height: 220px;
          // margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            // width: 56px;
            height: 58px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/process.png") no-repeat;
          }
          .filetext {
            font-size: 14px;
            margin-top: 50px;
            text-align: center;
          }
        }
        .cpuitem {
          width: 300px;
          height: 220px;
          margin-left: 30px;
          background: #fff;
          margin-bottom: 30px;
          border-radius: 16px;
          .fileitem {
            // width: 56px;
            height: 58px;
            margin-top: 70px;
            margin-left: 120px;
            background: url("../assets/CPU.png") no-repeat;
          }
          .filetext {
            font-size: 14px;
            margin-top: 50px;
            text-align: center;
          }
        }
      }
    }

    .KlCommunity {
      margin-top: 90px;
      .klHeader {
        width: 88px;
        height: 30px;
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        margin-left: 32px;
      }
      .klBox {
        margin-top: 30px;
        margin-bottom: 30px;
        .klItem {
          width: 960px;
          height: 200px;
          background: #ffffff;
          border-radius: 16px;
          margin-top: 16px;
          display: flex;
          .leftImg {
            width: 200px;
            height: 136px;
            margin: 32px;
            background: #d8d8d8;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .rightContent {
            margin-top: 32px;
            flex: 1;
            .itemTitle {
              font-size: 22px;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
            }
            .itemTip {
              width: 624px;
              height: 66px;
              font-size: 16px;
              font-weight: 400;
              color: #999999;
              line-height: 22px;
              margin-top: 8px;
              margin-bottom: 8px;
            }
            a {
              text-decoration: none;
              font-weight: 400;
              color: #3e7aec;
              line-height: 22px;
            }
          }
        }
      }
      .updateLog {
        display: flex;
        justify-content: space-between;
        .u-wrapper {
          box-sizing: border-box;
          border-radius: 16px;
          background-color: #ffffff;
          width: 310px;
          height: 150px;
          padding: 15px 32px;
          .u-header {
            display: flex;
            justify-content: space-between;
            .version-code {
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              line-height: 22px;
            }
            .modify-date {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
            }
          }
          .u-content {
            pre {
              font-family: PingFangSC-Regular, PingFang SC;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              overflow: hidden; //溢出隐藏
              text-overflow: ellipsis; //溢出字用省略号代替
              -webkit-box-orient: vertical; //设置对齐方式
              line-height: 20px;
              height: 80px;
            }
          }
        }
      }
      .moreKl {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        .klBtn {
          width: 200px;
          height: 50px;
          background: transparent;
          border-radius: 8px;
          border: 1px solid #3e7aec;
          font-size: 16px;
          font-weight: 400;
          color: #3e7aec;
          outline: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .latest-version {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        font-size: 22px;
        margin-bottom: 30px;
      }
      .sysTab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        .st-wrapper {
          img {
            width: 100px;
            height: 100px;
            cursor: pointer;
          }
          img:hover {
            opacity: 0.9;
          }
          div {
            margin-top: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            font-size: 16px;
            text-align: center;
            cursor: default;
          }
        }
      }
    }
  }
  .footer {
    // position: absolute;
    top: 2625px;
    left: 509px;
    .footertext {
      width: 100%;
      height: 80px;
      line-height: 80px;
      color: #999;
      font-size: 14px;
      text-align: center;
      background: #fff;
    }
  }
  .changImg {
  }

  .popbtnStyle {
    width: 70px;
    height: 30px;
    justify-content: center;
    padding: 0 0;
  }
}
</style>
